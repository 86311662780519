import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Icon, Strong, List, Section, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdDoneAll } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				Razor's Edge Barbershop
			</title>
			<meta name={"description"} content={"Від традиційних стрижок і гоління до сучасних укладок і стрижки бороди, наші перукарі володіють різними техніками, щоб гарантувати, що ви отримаєте бажаний вигляд. "} />
			<meta property={"og:title"} content={"Razor's Edge Barbershop"} />
			<meta property={"og:description"} content={"Від традиційних стрижок і гоління до сучасних укладок і стрижки бороди, наші перукарі володіють різними техніками, щоб гарантувати, що ви отримаєте бажаний вигляд. "} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6672acf9f0cb1d0025f65c49/images/1-2.jpg?v=2024-06-19T11:05:52.775Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6672acf9f0cb1d0025f65c49/images/1-2.jpg?v=2024-06-19T11:05:52.775Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6672acf9f0cb1d0025f65c49/images/1-2.jpg?v=2024-06-19T11:05:52.775Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6672acf9f0cb1d0025f65c49/images/1-2.jpg?v=2024-06-19T11:05:52.775Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6672acf9f0cb1d0025f65c49/images/1-2.jpg?v=2024-06-19T11:05:52.775Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6672acf9f0cb1d0025f65c49/images/1-2.jpg?v=2024-06-19T11:05:52.775Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6672acf9f0cb1d0025f65c49/images/1-2.jpg?v=2024-06-19T11:05:52.775Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header>
			<Override slot="link1">
				ПОСЛУГИ
			</Override>
		</Components.Header>
		<Section
			padding="60px 0 90px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			background="--color-darkL2"
			quarkly-title="Advantages/Features-16"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="center"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 60px 0px"
				lg-justify-content="center"
				sm-justify-content="flex-start"
			>
				<Text as="p" font="--base" color="--orange" margin="0px 0px 8px 0px">
					Наша талановита команда
				</Text>
				<Text
					as="h1"
					margin="0px"
					font="--headline2"
					color="--light"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="center"
					sm-text-align="left"
				>
					Наші послуги
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--light"
					width="70%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					text-align="center"
					sm-text-align="left"
				>
					У Razor's Edge Barbershop ми прагнемо забезпечити винятковий досвід догляду за кожним клієнтом. Наша команда кваліфікованих перукарів прагне надавати точні, стильні та індивідуальні стрижки та послуги з догляду за волоссям, які відповідають вашим індивідуальним потребам. Ми віримо, що кожен візит повинен дати вам відчуття бадьорості, впевненості та виглядати якнайкраще.
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--light"
					width="70%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					text-align="center"
					sm-text-align="left"
				>
					Наші перукарі не просто професіонали, вони митці з пристрастю до своєї справи. Кожен член нашої команди має унікальний набір навичок і досвіду, щоб забезпечити кожному клієнту послуги найвищої якості. З гострим поглядом на деталі та відданістю безперервному навчанню наші перукарі випереджають останні тенденції та техніки, щоб забезпечити вам найкращий досвід.
				</Text>
			</Box>
			<Box
				display="flex"
				width="100%"
				lg-justify-content="center"
				lg-width="100%"
				align-items="center"
				lg-padding="0px 0px 0px 0px"
				justify-content="center"
				margin="0px 0px 36px 0px"
				position="relative"
			>
				<Box
					height="553px"
					width="100%"
					position="absolute"
					bottom="-0.239501953125px"
					top="0px"
				/>
				<Image
					src="https://uploads.quarkly.io/6672acf9f0cb1d0025f65c49/images/1-1.jpg?v=2024-06-19T11:05:52.740Z"
					display="block"
					margin="0px 0px 0px 0px"
					border-radius="16px"
					srcSet="https://smartuploads.quarkly.io/6672acf9f0cb1d0025f65c49/images/1-1.jpg?v=2024-06-19T11%3A05%3A52.740Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6672acf9f0cb1d0025f65c49/images/1-1.jpg?v=2024-06-19T11%3A05%3A52.740Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6672acf9f0cb1d0025f65c49/images/1-1.jpg?v=2024-06-19T11%3A05%3A52.740Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6672acf9f0cb1d0025f65c49/images/1-1.jpg?v=2024-06-19T11%3A05%3A52.740Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6672acf9f0cb1d0025f65c49/images/1-1.jpg?v=2024-06-19T11%3A05%3A52.740Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6672acf9f0cb1d0025f65c49/images/1-1.jpg?v=2024-06-19T11%3A05%3A52.740Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6672acf9f0cb1d0025f65c49/images/1-1.jpg?v=2024-06-19T11%3A05%3A52.740Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<List
				margin="40px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				as="ul"
				sm-margin="24px 0 0 0"
				list-style-type="none"
				font="normal normal 18px/150% sans-serif"
				display="grid"
				flex-direction="column"
				grid-gap="25px"
				lg-margin="32px 0px 0px 0px"
				grid-template-columns="repeat(2, 1fr)"
				lg-grid-template-columns="1fr"
			>
				<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="md"
						icon={MdDoneAll}
						size="40px"
						color="--primary"
						margin="5px 0 0 0"
					/>
					<Text
						padding="0"
						margin="0"
						color="--light"
						font="18px/27px --fontFamily-sans"
						lg-max-width="720px"
					>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Стрижки
							<br />
						</Strong>
						<br />
						Класичні стрижки: Наші класичні стрижки поза часом і підібрані відповідно до вашого особистого стилю. Кожен виріз оброблено з точністю, щоб забезпечити відполірований вигляд.{"\n"}
						<br />
						<br />
						{" "}Традиційний: ідеально підходить для тих, хто віддає перевагу чистому, професійному вигляду.{"\n"}
						<br />
						<br />
						{" "}Модерн: Для сучасного відтінку класичної стрижки, що включає останні тенденції.
						<br />
						<br />
						{"\n "}На замовлення: Персоналізовано відповідно до ваших унікальних уподобань і стилю.
Вицвітання та звуження: Досягніть різкого, чистого вигляду за допомогою наших майстерно виконаних згасань і звужень.{"\n"}
						<br />
						<br />
						{" "}High Fade: Сміливий вигляд із висококонтрастним покриттям.
 Низьке вицвітання: Тонкий і плавний, ідеально підходить для природного переходу.{"\n"}
						<br />
						<br />
						{" "}Вицвітання шкіри: Надзвичайно щільне покриття для яскравого та гострого стилю.
					</Text>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="md"
						icon={MdDoneAll}
						size="40px"
						color="--primary"
						margin="5px 0 0 0"
					/>
					<Text
						padding="0"
						margin="0"
						color="--light"
						font="18px/27px --fontFamily-sans"
						lg-max-width="720px"
					>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Гоління
							<br />
						</Strong>
						<br />
						{" "}Гоління гарячим рушником: Відчуйте максимальне розслаблення з нашим розкішним голінням гарячим рушником. Ця послуга включає заспокійливу обробку гарячим рушником, гладке гоління та зволоження.
						<br />
						<br />
						{"\n "}Традиційне гоління: Класичне гоління бритвою для гладкої та чистої поверхні.{"\n"}
						<br />
						<br />
						{" "}Формування бороди: покращте свою бороду за допомогою точного формування та деталізації.
						<br />
						<br />
						Експрес-гоління: Для тих, хто в дорозі, наше експрес-гоління пропонує швидке та ефективне рішення для догляду.{"\n"}
						<br />
						<br />
						{" "}Швидке очищення: ідеально підходить для підтримки доглянутого вигляду між повним голінням.
 Neck Shave: Тримайте декольте чітким і чистим.
					</Text>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="md"
						icon={MdDoneAll}
						size="40px"
						color="--primary"
						margin="5px 0 0 0"
					/>
					<Text
						padding="0"
						margin="0"
						font="18px/27px --fontFamily-sans"
						color="--light"
						lg-max-width="720px"
					>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Догляд за бородою
							<br />
							<br />
						</Strong>
						Підстригання бороди: Підтримуйте свою бороду якнайкраще за допомогою наших професійних послуг з підстригання.
						<br />
						<br />
						{"\n "}Повне підстригання бороди: Досконало сформуйте свою бороду.
						<br />
						<br />
						{"\n "}Детальна робота: Налаштуйте свою бороду за допомогою точного деталізації та окантовки.
						<br />
						<br />
						{"\n"}Засоби для лікування бороди:  Доглядайте та кондиціонуйте свою бороду за допомогою наших засобів для лікування преміум-класу.{"\n"}
						<br />
						<br />
						{" "}Зволожуюча процедура: зволожує та пом’якшує бороду для здорового вигляду.{"\n"}
						<br />
						<br />
						{" "}Застосування олії для бороди: Покращуйте блиск і догляд за допомогою наших високоякісних олій для бороди.
					</Text>
				</Box>
				<Box margin="0px 0px 0px 0px" display="flex" grid-gap="16px">
					<Icon
						display="block"
						category="md"
						icon={MdDoneAll}
						size="40px"
						color="--primary"
						margin="5px 0 0 0"
					/>
					<Text
						padding="0"
						margin="0"
						color="--light"
						font="18px/27px --fontFamily-sans"
						lg-max-width="720px"
					>
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Спеціальні послуги
							<br />
							<br />
						</Strong>
						Укладання волосся: Досягніть ідеального вигляду для будь-якого випадку за допомогою наших професійних послуг укладання.{"\n"}
						<br />
						<br />
						{" "}Стилізація подій: Виглядайте якнайкраще на весіллях, вечірках та інших особливих подіях.{"\n"}
						<br />
						<br />
						{" "}Повсякденний стиль: Зберігайте відшліфований зовнішній вигляд за допомогою наших рішень для повсякденного стилю.{"\n"}
						<br />
						<br />
						Догляд за шкірою голови: Сприяйте здоров’ю шкіри голови за допомогою наших омолоджуючих процедур.
 Відлущувальна процедура: видаляє мертві клітини шкіри та стимулює кровообіг.{"\n"}
						<br />
						<br />
						{" "}Зволожуюча процедура: Зволожте та заспокойте шкіру голови для покращення здоров’я та комфорту.
					</Text>
				</Box>
			</List>
			<Text
				as="p"
				margin="3rem 0 0 0"
				font="--lead"
				font-size="20px"
				font-weight="300"
				color="--light"
				width="100%"
				md-width="100%"
				md-margin="0px 0px 0px 0px"
				md-padding="0px 0px 0px 0px"
				sm-font="--base"
				text-align="center"
				sm-text-align="left"
			>
				У Razor's Edge ми пропонуємо широкий спектр послуг, розроблених для задоволення всіх ваших потреб у догляді. Наші пропозиції включають традиційні та сучасні стрижки, розкішне гоління, догляд за бородами тощо. Кожна послуга виконується з ретельністю, використовуючи продукти преміум-класу для забезпечення найкращих результатів.
			</Text>
		</Section>
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://uploads.quarkly.io/6672acf9f0cb1d0025f65c49/images/2-4%20%281%29.jpg?v=2024-06-19T11:05:52.761Z) center center/cover no-repeat"
			min-height="100vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline3"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					І це лише початок!
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
				>
					У Razor's Edge Barbershop ми пропонуємо широкий спектр послуг, щоб задовольнити всі ваші потреби в догляді. Щоб дізнатися більше про весь спектр наших пропозицій, ми запрошуємо вас відвідати нас або зв’язатися з нами для отримання детальної інформації. Наш привітний персонал завжди готовий допомогти вам і забезпечити найкраще обслуговування.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					Контакти
				</Link>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text1" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});